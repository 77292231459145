var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"用户名","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['fapiaoname', {rules: [{required: true, message: '请输入'}]}]),expression:"['fapiaoname', {rules: [{required: true, message: '请输入'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"用户密码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {rules: [{required: true, message: '请输入'}]}]),expression:"['password', {rules: [{required: true, message: '请输入'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"创建日期","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['create_time', {rules: [{required: true, message: '请输入'}]}]),expression:"['create_time', {rules: [{required: true, message: '请输入'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"昵称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['nickname', {rules: [{required: true, message: '请输入'}]}]),expression:"['nickname', {rules: [{required: true, message: '请输入'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"头像","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['avatar', {rules: [{required: true, message: '请输入'}]}]),expression:"['avatar', {rules: [{required: true, message: '请输入'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"角色","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'role',
          {
            rules: [
              { required: true, message: '请选择' },
            ],
          },
        ]),expression:"[\n          'role',\n          {\n            rules: [\n              { required: true, message: '请选择' },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"选择"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v(" 管理员 ")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v(" 企业 ")]),_c('a-select-option',{attrs:{"value":3}},[_vm._v(" 物业 ")])],1)],1),_c('a-form-item',{attrs:{"label":"是否活跃","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_active', {initialValue: false, valuePropName: 'checked'}]),expression:"['is_active', {initialValue: false, valuePropName: 'checked'}]"}]})],1),_c('a-form-item',{attrs:{"label":"邮箱","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {rules: [{required: false, message: '请输入'}]}]),expression:"['email', {rules: [{required: false, message: '请输入'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"手机号","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['cellphone', {rules: [{ required: true, message: '请输入正确的手机号', pattern: /^1[3456789]\d{9}$/ }]}]),expression:"['cellphone', {rules: [{ required: true, message: '请输入正确的手机号', pattern: /^1[3456789]\\d{9}$/ }]}]"}]})],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.buttonCol,false),[_c('a-row',[_c('a-col',{attrs:{"span":"6"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.handleSubmit}},[_vm._v("提交")])],1),_c('a-col',{attrs:{"span":"10"}},[_c('a-button',{on:{"click":_vm.handleGoBack}},[_vm._v("返回")])],1),_c('a-col',{attrs:{"span":"8"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }