<template>
  <div>
    <a-form :form="form" >
      <a-form-item label="用户名" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="['fapiaoname', {rules: [{required: true, message: '请输入'}]}]" />
      </a-form-item>
      <a-form-item label="用户密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="['password', {rules: [{required: true, message: '请输入'}]}]" />
      </a-form-item>
      <a-form-item label="创建日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="['create_time', {rules: [{required: true, message: '请输入'}]}]" />
      </a-form-item>
      <a-form-item label="昵称" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="['nickname', {rules: [{required: true, message: '请输入'}]}]" />
      </a-form-item>
      <a-form-item label="头像" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="['avatar', {rules: [{required: true, message: '请输入'}]}]" />
      </a-form-item>
      <a-form-item label="角色" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-select
          v-decorator="[
            'role',
            {
              rules: [
                { required: true, message: '请选择' },
              ],
            },
          ]"
          placeholder="选择"
        >
          <a-select-option :value="1">
            管理员
          </a-select-option>
          <a-select-option :value="2">
            企业
          </a-select-option>
          <a-select-option :value="3">
            物业
          </a-select-option>
        </a-select>      </a-form-item>
      <a-form-item label="是否活跃" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-switch v-decorator="['is_active', {initialValue: false, valuePropName: 'checked'}]" />
      </a-form-item>
      <a-form-item label="邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="['email', {rules: [{required: false, message: '请输入'}]}]" />
      </a-form-item>
      <a-form-item label="手机号" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="['cellphone', {rules: [{ required: true, message: '请输入正确的手机号', pattern: /^1[3456789]\d{9}$/ }]}]" />
      </a-form-item>
      <a-form-item
        v-bind="buttonCol"
      >
        <a-row>
          <a-col span="6">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="10">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
          <a-col span="8"></a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
// import { fapiao_update } from '@/api/fapiao'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    this.$nextTick(() => {
      this.loadEditInfo(this.record)
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
    //     fapiao_update(values, this.id)
    //       .then((res) => {
    //         this.handleGoBack()
    //         console.log(res, '修改成功')
    // })
      })
    },
    handleGetInfo () {

    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['id', 'fapiaoname', 'password', 'create_time', 'last_update', 'nickname', 'avatar', 'role', 'email', 'cellphone', 'is_active'])
        console.log('formData', formData)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
