import request from '@/utils/request'

const fapiaoApi = {
  fapiao_list: '/fapiao/',
  fapiao_create: '/fapiao/',
  fapiao_delete: '/fapiao/'
}

/**
 * 获取授权车辆列表
 */
export function fapiao_list (parameter) {
  return request({
    url: fapiaoApi.fapiao_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function fapiao_create (parameter) {
  return request({
    url: fapiaoApi.fapiao_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function fapiao_delete (fapiao_id) {
  return request({
    url: fapiaoApi.fapiao_delete + fapiao_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
