<template>
  <a-modal
    title="新建发票"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="企业名称">
              <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="纳税人识别号">
              <a-input v-decorator="['code', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="是否专用发票">
              <a-switch v-decorator="['is_special', {initialValue: false, valuePropName: 'checked'}]" @change="specialChange" />
            </a-form-item>
            <a-form-item label="注册地址">
              <a-input v-decorator="['address', {rules: [{required: is_required, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="电话">
              <a-input v-decorator="['phone', {rules: [{required: is_required, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="开户行">
              <a-input v-decorator="['bank', {rules: [{required: is_required, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="银行账号">
              <a-input v-decorator="['account', {rules: [{required: is_required, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
// 表单字段
const fields = ['id', 'create_time', 'last_update', 'username', 'password', 'nickname',
  'avatar', 'role', 'email', 'cellphone', 'location', 'is_active']
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      options: [
        {
          value: 'zhejiang',
          label: 'Zhejiang',
          children: [
            {
              value: 'hangzhou',
              label: 'Hangzhou',
              children: [
                {
                  value: 'xihu',
                  label: 'West Lake'
                }
              ]
            }
          ]
        },
        {
          value: 'jiangsu',
          label: 'Jiangsu',
          children: [
            {
              value: 'nanjing',
              label: 'Nanjing',
              children: [
                {
                  value: 'zhonghuamen',
                  label: 'Zhong Hua Men'
                }
              ]
            }
          ]
        }
      ],
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      is_required: false
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    specialChange (value) {
      console.log(value)
      this.is_required = value
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
